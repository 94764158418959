<template>
  <div id="updateEnterprise" class="contenedor">
    <loader :show="show" />
    <sesion :expiration="expiration" />
    <Sidebar></Sidebar>
    <v-container class="base">
      <v-row no-gutters class="titulo ml-10 mt-5"
        >ACTUALIZACIÓN DE EMPRESA PAGADORA</v-row
      >
      <v-container fluid class="addEnterprise">
        <v-row no-gutters justify="end">
          <v-col
            cols="12"
            lg="5"
            md="5"
            sm="5"
            class="marginCol formularios"
            align-self="center"
          >
            <v-row class="mb-2">
              <div class="form-group">
                <label for="reason">Razón Social</label>
                <input
                  type="text"
                  name="reason"
                  id="reason"
                  placeholder="Obligatorio"
                  class="inputs"
                  autocomplete="false"
                  v-model="enterpriseSelect.razonSocial"
                  required
                  maxlength="35"
                  @keypress="alfaNumerico($event)"
                />
              </div>
            </v-row>
            <v-row class="mb-2">
              <div class="form-group">
                <label for="rfc">RFC</label>
                <input
                  type="text"
                  name="rfc"
                  id="rfc"
                  placeholder="Obligatorio"
                  class="inputs"
                  autocomplete="false"
                  v-model="enterpriseSelect.rfc"
                  required
                  maxlength="12"
                  @keypress="alfaNumerico($event)"
                />
              </div>
            </v-row>
            <v-row class="mb-2">
              <div class="form-group">
                <label for="imss">IMSS</label>
                <input
                  type="text"
                  name="imss"
                  id="imss"
                  placeholder="ABC12345102"
                  class="inputs"
                  autocomplete="false"
                  v-model="enterpriseSelect.nss"
                  required
                  maxlength="11"
                  @keypress="alfaNumerico($event)"
                />
              </div>
            </v-row>
            <v-row class="mb-2">
              <div class="form-group">
                <label for="logo">Logotipo</label>
                <input
                  type="file"
                  name="logo"
                  id="log"
                  class="inputs"
                  @change="onFileChange($event)"
                />
              </div>
            </v-row>
            <v-row class="mb-2">
              <div class="form-group">
                <label for="country">País</label>
                <input
                  type="text"
                  name="country"
                  id="country"
                  placeholder="Obligatorio"
                  class="inputs"
                  autocomplete="false"
                  v-model="enterpriseSelect.pais"
                  required
                  maxlength="35"
                  readonly
                  @keypress="alfaNumerico($event)"
                />
              </div>
            </v-row>
            <v-row class="mb-2">
              <div class="form-group">
                <label for="cp">C.P.</label>
                <input
                  type="text"
                  name="cp"
                  id="cp"
                  placeholder="Obligatorio"
                  class="inputs"
                  autocomplete="false"
                  v-model="enterpriseSelect.codigoPostal"
                  required
                  maxlength="5"
                  @keypress="isNumber($event)"
                  @change="consultaDireccion()"
                />
              </div>
            </v-row>
            <v-row class="mb-2">
              <div class="form-group">
                <label for="state">Estado</label>
                <input
                  type="text"
                  name="state"
                  id="state"
                  placeholder="Obligatorio"
                  class="inputs"
                  autocomplete="false"
                  v-model="state"
                  required
                  maxlength="35"
                  readonly
                  @keypress="alfaNumerico($event)"
                />
              </div>
            </v-row>
            <v-row class="mb-2">
              <div class="form-group">
                <label for="municipality">Municipio</label>
                <input
                  type="text"
                  name="municipality"
                  id="municipality"
                  placeholder="Obligatorio"
                  class="inputs"
                  autocomplete="false"
                  v-model="municipality"
                  required
                  maxlength="35"
                  readonly
                  @keypress="alfaNumerico($event)"
                />
              </div>
            </v-row>
            <v-row class="mb-2">
              <div class="form-group">
                <label for="suburb">Colonia</label>

                <select
                  name="suburb"
                  id="suburb"
                  v-model="enterpriseSelect.asentamientoId"
                >
                  <option disabled value="0">Selecciona Colonia</option>
                  <option
                    v-for="suburb in suburbs"
                    :key="suburb.id"
                    v-bind:value="suburb.id"
                    :selected="
                      suburb.id === enterpriseSelect.asentamientoId
                        ? false
                        : true
                    "
                  >
                    {{ suburb.name }}
                  </option>
                </select>
              </div>
            </v-row>
            <v-row class="mb-2">
              <div class="form-group">
                <label for="street">Calle</label>
                <input
                  type="text"
                  name="street"
                  id="street"
                  placeholder="Obligatorio"
                  class="inputs"
                  autocomplete="false"
                  v-model="enterpriseSelect.calle"
                  required
                  maxlength="35"
                  @keypress="alfaNumerico($event)"
                />
              </div>
            </v-row>
            <v-row class="mb-2">
              <div class="form-group">
                <label for="exterior">N° Exterior</label>
                <input
                  type="text"
                  name="exterior"
                  id="exterior"
                  placeholder="Obligatorio"
                  class="inputs"
                  autocomplete="false"
                  v-model="enterpriseSelect.numeroExterior"
                  required
                  maxlength="20"
                  @keypress="alfaNumerico($event)"
                />
              </div>
            </v-row>
            <v-row class="mb-2">
              <div class="form-group">
                <label for="interior">N° Interior</label>
                <input
                  type="text"
                  name="interior"
                  id="interior"
                  placeholder="Obligatorio"
                  class="inputs"
                  autocomplete="false"
                  v-model="enterpriseSelect.numeroInterior"
                  required
                  maxlength="20"
                  @keypress="alfaNumerico($event)"
                />
              </div>
            </v-row>
          </v-col>
          <v-col cols="12" lg="6" md="6" sm="6" align-self="center">
            <v-row justify="center" id="previewRow">
              <img
                id="preview"
                v-if="enterpriseSelect.logo != ''"
                :src="enterpriseSelect.logo"
                style="width: 50% !important; height: 50% !important"
              />
              <img
                id="preview"
                v-else
                :src="url"
                style="width: 50% !important; height: 50% !important"
              />
            </v-row>
            <v-row justify="center">
              <button class="botonAmarillo" @click="cancel()">Cancelar</button>
              <!-- <button class="botonAmarilloDerecho" @click="validacion()">Guardar</button> -->
              <button class="botonAmarilloDerecho" @click="guardar()">
                Guardar
              </button>
            </v-row>
          </v-col>
        </v-row>
      </v-container>
    </v-container>
    <!--Error-->
    <v-dialog v-model="error" width="400">
      <v-card>
        <v-card-title class="red white--text">
          <span class="headline">Software</span>
        </v-card-title>
        <v-card-text>
          <br />
          <h3 class="text-md-center text-sm-center text-lg-center">
            {{ respuesta }}
          </h3>
          <br />
          <ul style>
            <li v-for="error in errors" :key="error.name">{{ error }}</li>
          </ul>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="warning" text @click="error = false">Corregir</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!--Validación de datos-->
    <v-dialog v-model="advice" width="400">
      <v-card>
        <v-card-title class="orange white--text">
          <span class="headline">Alerta</span>
        </v-card-title>
        <v-card-text>
          <br />
          <h3 class="text-md-center text-lg-center text-sm-center">
            ¿Esta seguro de almacenar la empresa?
          </h3>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn color="error" text @click="aux()">Cancelar</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="success" text @click="guardar()">Aceptar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!--Confirmación de datos-->
    <v-dialog v-model="confirmation" width="400">
      <v-card>
        <v-card-title class="orange white--text">
          <span class="headline">Empresa</span>
        </v-card-title>
        <v-card-text>
          <br />
          <h3 class="text-lg-center text-sm-center text-md-center">
            {{ respuesta }}
          </h3>
          <br />
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="success" text @click="aux()">OK</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import Sidebar from "@/components/Sidebar.vue";
import Loader from "@/components/Loader";
import Sesion from "@/components/Sesion";
export default {
  components: {
    Sidebar,
    Loader,
    Sesion
  },
  data() {
    return {
      show: false,
      expiration:false,
      search: "",
      dialog: false,
      rfc: "",
      imss: "",
      reason: "",
      errors: [],
      respuesta: "",
      confirmation: false,
      advice: false,
      error: false,
      country: "",
      state: "",
      cp: "",
      exterior: "",
      interior: "",
      municipality: "",
      suburb: 0,
      street: "",
      suburbs: [],
      url: null,
      enterpriseSelect: [],
      formData: new FormData(),
    };
  },
  methods: {
    onFileChange(evt) {
      const file = evt.target.files[0];
      this.url = URL.createObjectURL(file);
      var fileBase = evt.target.files[0];
      var reader = new FileReader();
      reader.onloadend = function () {
        console.log("RESULT", reader.result);
        sessionStorage.setItem("base64img", reader.result);
      };
      reader.readAsDataURL(fileBase);

      this.selectedFile = evt.target.files[0];
      this.formData.append("Logo", this.selectedFile, this.selectedFile.name);
    },

    alfaNumerico: function (evt) {
      var regex = new RegExp("^[a-zA-ZÀ-ÿ\u00f1\u00d10-9 ]+$"); // Letras y números
      var str = String.fromCharCode(!evt.charCode ? evt.which : evt.charCode);
      if (regex.test(str)) {
        return true;
      }
      evt.preventDefault();
      return false;
    },
    isNumber: function (evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if ((charCode > 31 && charCode < 48) || charCode > 57) {
        evt.preventDefault();
      } else return true;
    },
    validaImss() {
      var imss = this.enterpriseSelect.imss;
      if (imss.length != 11) {
        this.errors.push("Número de IMSS incorrecto");
        return false;
      } else {
        console.log("Correcto");
        console.log(imss.length);
        return false;
      }
    },
    validaRfc() {
      var strCorrecta;
      strCorrecta = this.enterpriseSelect.rfc;
      if (this.dataEnterprise.rfc == 12) {
        console.log(this.dataUser[0].rfc.length);
        var valid =
          "^(([A-Z]|[a-z]|s){1})(([A-Z]|[a-z]){3})([0-9]{6})((([A-Z]|[a-z]|[0-9]){3}))";
      }
      var validRfc = new RegExp(valid);
      var matchArray = strCorrecta.match(validRfc);
      if (matchArray == null) {
        this.errors.push("El RFC es incorrecto favor de verificarlo.");
        return false;
      } else {
        console.log("Correcto");
        return true;
      }
    },
    validaCp() {
      console.log(this.enterpriseSelect.cp);
      var cp = this.enterpriseSelect.cp;
      if (cp.length != 5) {
        this.errors.push("Código Postal incorrecto");
        return false;
      } else {
        console.log("Correcto");
        console.log(cp.length);
        return false;
      }
    },
    validacion() {
      this.errors = [];
      this.validaRfc();
      this.validaImss();
      this.validaCp();
      if (
        this.enterpriseSelect.reason == "" ||
        this.enterpriseSelect.reason == " "
      ) {
        this.errors.push("Se debe especificar la Razón Social.");
      }
      if (
        this.enterpriseSelect.country == "" ||
        this.enterpriseSelect.country == " "
      ) {
        this.errors.push("Se debe especificar el país.");
      }
      if (
        this.enterpriseSelect.state == "" ||
        this.enterpriseSelect.state == " "
      ) {
        this.errors.push("Se debe especificar el estado.");
      }
      if (
        this.enterpriseSelect.municipality == "" ||
        this.enterpriseSelect.municipality == " "
      ) {
        this.errors.push("Se debe especificar el municipio.");
      }
      if (
        this.enterpriseSelect.suburb == "" ||
        this.enterpriseSelect.suburb == 0
      ) {
        this.errors.push("Se debe especificar la colonia.");
      }
      if (
        this.enterpriseSelect.street == "" ||
        this.enterpriseSelect.street == " "
      ) {
        this.errors.push("Se debe especificar la calle.");
      }
      if (
        this.enterpriseSelect.country == "" ||
        this.enterpriseSelect.country == " "
      ) {
        this.errors.push("Se debe especificar el país.");
      }
      if (
        this.enterpriseSelect.state == "" ||
        this.enterpriseSelect.state == " "
      ) {
        this.errors.push("Se debe especificar el estado.");
      }
      if (
        this.enterpriseSelect.exterior == "" ||
        this.enterpriseSelect.exterior == " "
      ) {
        this.errors.push("Se debe especificar el número exterior.");
      }
      if (
        this.enterpriseSelect.interior == "" ||
        this.enterpriseSelect.interior == " "
      ) {
        this.errors.push("Se debe especificar el número interior.");
      }
      if (this.errors.length == 0) {
        return (this.advice = true);
      } else {
        this.respuesta = "Por favor corrige el siguiente error:";
        this.error = true;
      }
    },
    cancel() {
      localStorage.enterpriseId = "";
      this.$router.push("/enterprise");
    },
    guardar() {
      this.advice = false;
      console.log("llegue");
      var logo = null;
      if (sessionStorage.getItem("base64img") == "null") {
        logo = this.enterpriseSelect.logo;
      } else {
        logo = sessionStorage.getItem("base64img");
      }

      this.formData.append("RazonSocial", this.enterpriseSelect.razonSocial);
      this.formData.append("NSS", this.enterpriseSelect.nss);
      this.formData.append("RFC", this.enterpriseSelect.rfc);
      this.formData.append("Pais", this.enterpriseSelect.pais);
      this.formData.append("CodigoPostal", this.enterpriseSelect.codigoPostal);
      this.formData.append(
        "AsentamientoId",
        this.enterpriseSelect.asentamientoId
      );
      this.formData.append(
        "NumeroExterior",
        this.enterpriseSelect.numeroExterior
      );
      this.formData.append(
        "NumeroInterior",
        this.enterpriseSelect.numeroInterior
      );
      this.formData.append("Calle", this.enterpriseSelect.calle);
      this.show=true;
      axios
        .put(Server + "/empresas/" + this.enterpriseSelect.id, this.formData, {
          headers: {
            Authorization: localStorage.token,
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          console.log(response);
          this.respuesta = "La Empresa fue guardada con éxito";
          this.show=false;
          this.confirmation = true;
        })
        .catch((e) => {
          console.log(e);
          this.show=false;
          if (e.response.status == 401) {
            this.expiration = true;
          } else {
            this.respuesta = "La Empresa no fue almacenada";
            this.confirmation = true;
          }
        });
    },
    aux() {
      this.confirmation = false;
      localStorage.enterpriseId = "";
      this.$router.push("/enterprise");
    },
    consultaDireccion() {
      console.log("Consulta dirección");
      console.log(this.enterpriseSelect);
      if (this.enterpriseSelect.codigoPostal.length == 5) {
        this.suburbs = [];
        this.show = true;
        axios
          .get(Server + "/direcciones/" + this.enterpriseSelect.codigoPostal,{
          headers: {
            Authorization: localStorage.token,
          },
        })
          .then((response) => {
            console.log(response);
            this.address = response.data;
            this.state = this.address[0].estado;
            this.municipality = this.address[0].municipio;
            this.address.forEach((value, index) => {
              this.suburbs.push({
                id: value.asentamientoId,
                name: value.colonia,
              });
            });
            this.show = false;
          })
          .catch((e) => {
            console.log(e);
            this.show = false;
            if (e.response.status == 401) {
              this.expiration = true;
            }
          });
      } else {
        console.log("Menor a 5");
      }
    },
    recuperar() {
      //console.log(localStorage.enterpriseId);
      this.show = true;
      axios
        .get(Server + "/empresas/" + localStorage.enterpriseId,{
          headers: {
            Authorization: localStorage.token,
          },
        })
        .then((response) => {
          //console.log(response);
          this.enterpriseSelect = response.data;
          this.consultaDireccion();
          this.show = false;
        })
        .catch((e) => {
          console.log(e);
          this.show = false;
          if (e.response.status == 401) {
            this.expiration = true;
          }
        });
    },
  },
  created() {
    sessionStorage.setItem("base64img", null);
    this.recuperar();
  },
};
</script>